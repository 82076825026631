import React from "react";
import { FaFacebookSquare, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { IconContext } from "react-icons";

function Footer() {
  return (
    <div
      className="d-flex p-2  fixed-bottom  justify-content-between align-items-center mt-5"
      style={{ background: "rgb(235 25 116)" }}
    >
      <IconContext.Provider
        value={{
          color: "white",
          size: "1.5em",
          className: "global-class-name",
        }}
      >
        <div className="social-media iconMedia">
          <a
            rel="noreferrer"
            className="me-3"
            href="https://api.whatsapp.com/send?phone=541134304441&text=%20!Hola!%20Me%20interesa%20saber%20m%C3%A1s%20sobre%20las%20clases"
          >
            <FaWhatsapp />
          </a>

          <a
            rel="noreferrer"
            className="me-3"
            target="_blank"
            href="https://www.facebook.com/StretchingGlobalActivoLomas/"
          >
            <FaFacebookSquare />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/esfero_stretch_postural"
          >
            <FaInstagram />
          </a>
        </div>
      </IconContext.Provider>
      <div
        className="text-white"
        style={{ fontSize: "0.8rem" }}
      >
        Developed by

        <a href="https://github.com/barbiwonderland" rel="noreferrer" target="_blank" className="text-decoration-none text-white">
          {" "}
          Barbiwonderland{" "}
        </a>
      </div>
    </div>
  );
}

export default Footer;
