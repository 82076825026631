import React from "react-router";
import { Carousel } from "react-bootstrap";
import { useState, useEffect } from "react";
import fetchImages from "../../services/fetchImages";

const Gallery = () => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    fetchImages();

    const loadImages = async () => {
      const urls = await fetchImages();
      setFiles(urls);
    };
    loadImages();
  }, []);

  const images = files.map((x) =>
    x ? (
      <Carousel.Item>
        <img className="d-block w-100" src={x} alt={x.name} />
      </Carousel.Item>
    ) : null
  );

  return (
    <>
      <div id="gallery">
        <Carousel>{images}</Carousel>
      </div>
    </>
  );
};
export default Gallery;
