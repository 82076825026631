import { storage } from "../firebase";

const fetchProfilePic = () =>
{
  let refe = storage.ref(`profilePic/pic-1`);
  return refe
    .getDownloadURL()
    .then((url) =>
    {
      return url;
    })
    .catch((error) =>
    {
      console.log(error);
    });
};

export default fetchProfilePic;
