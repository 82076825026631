import React from "react";


function Admin() {

  return (
    <>
    <div className="col">
    <h3 className="text-center mt-5">Bienvenido a la pagina de administración</h3>
    </div>
    
    </>
  );
}
export default Admin;
