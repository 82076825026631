import React from 'react'
import { Route } from 'react-router'
import Admin from "../pages/admin"
import EditCarousel from '../pages/admin/EditCarousel'
import EditProfile from '../pages/admin/EditProfilePic'
import { DashboardNav } from '../pages/admin/components/DashboardNav'
import { EditAbout } from '../pages/admin/EditAbout'
import { EditInfo } from '../pages/admin/EditInfo'
export function AdminRoutes() {


    return (
        <>
        <div className="container-fluid h-100">
                <div className="row flex no-wrap h-100">        
                <DashboardNav />
            
                <Route exact path="/admin" component={Admin} />
                <Route  path="/admin/editProfile" component={EditProfile} />
                <Route  path="/admin/editCarousel" component={EditCarousel} />
                <Route  path="/admin/editAbout" component={EditAbout} />
                <Route  path="/admin/editInfo" component={EditInfo} />

             </div>
             </div>
        </>
    )
}
