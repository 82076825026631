import React from "react";
import styled, { keyframes } from "styled-components";
import banner from "../../assets/images/bg-home.jpg";
import { fadeIn } from "react-animations";
import Header from "../public/components/header";
import Gallery from "../public/Gallery";
import Location from "../public/Location";
import About from "../public/About";
import Info from "../public/Info";
import { HashLink as Link } from "react-router-hash-link";
import Footer from "./components/Footer";

function Home() {
  // const history = useHistory();
  // const handleClick = () => {
  //   history.push("/#info");
  // };

  const fadeInAnimation = keyframes`${fadeIn}`;
  const Main = styled.div`
    background: rgba(0, 0, 0, 0) url(${banner});
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    animation: 5s ${fadeInAnimation};
    display: flex;
  `;
  const BannerText = styled.div`
    font-size: 1.5rem;
    color: white;
    padding: 5%;
    width: 80%;
    height: 60%;
    background-color: rgb(110 29 29 / 30%);
    align-self: center;
    margin: 0 auto;
  `;

  return (
    <>
      <div className="mainHome mb-1">
        <Header />
        <div
          className="
      "
          id="home"
        >
          <Main>
            <BannerText>
              <div className="text-center">
                Esfero Sga, mejorando tu armonia postural
              </div>
              <Link
                smooth
                to="#info"
                className="btn btn-light d-flex justify-content-center mx-auto shadow-none mt-1 about-button"
              
              >
                Conocer más
              </Link>
            </BannerText>
          </Main>
        </div>
        <div className="section-margin">
          <div className="info">
            <Info />
          </div>
          <About />
        </div>
        <div className="section-margin">
          <Gallery />
        </div>
        <div className="section-margin pb-5">
          <Location />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
