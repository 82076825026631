import React from 'react'
import { GoHome } from "react-icons/go";
import { MdPhotoSizeSelectActual, MdPhotoLibrary, MdInfoOutline } from "react-icons/md";
import { SiAboutdotme } from "react-icons/si";
import { BackButton } from '../../../components/comeBackButton';
import LogOutButton from '../../../components/LogOutButton';
import { Link, NavLink } from 'react-router-dom';
export function DashboardNav() {


    return (
        <>

            <div className="bg-custom col-auto col-2  col-md-2 h-100 text-white d-flex flex-column justify-content-between py-3">
                <div className="bg-custom h-100" >
                    <Link  to="/admin" className=' nav-NavLink  mt-4 d-flex text-decoration-none align-items-center '>
                        <span className='fs-4 d-none d-sm-inline text-white'>Silvana Santiago - Strech</span>
                    </Link>
                    <ul className="nav nav-pills flex-column mt-5 d-flex gap-4 fs-5">
                        <li className="nav-item">
                            <NavLink activeClassName="active" exact to="/admin" className="nav-NavLink d-flex fs-6" aria-current="page">
                            <GoHome size={25} />

                                <span className=' ms-2 d-none d-sm-inline ml-5'>Home</span>

                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/admin/editProfile" className="nav-NavLink d-flex fs-6" aria-current="page">
                                <MdPhotoSizeSelectActual size={25} />
                                <span className=' d-none ms-2 d-sm-inline ml-5'>Foto de perfil</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/admin/editCarousel" className="nav-NavLink d-flex fs-6 " aria-current="page">
                                <MdPhotoLibrary size={25} />
                                <span className=' d-none ms-2 d-sm-inline ml-5'> Carrousel</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/admin/editAbout" className="nav-NavLink d-flex fs-6 " aria-current="page">
                                 <SiAboutdotme size={25} /> 
                                <span className=' d-none ms-2 d-sm-inline ml-5'> Nosotros</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/admin/editInfo" className="nav-NavLink  d-flex fs-6 " aria-current="page">
                                <MdInfoOutline size={25} />
                                <span className=' d-none ms-2 d-sm-inline ml-5'> Info general</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className=" d-flex justify-content-between flex-wrap gap-5">
                    <BackButton />
                    <LogOutButton />
                </div>

            </div>


        </>
    )
}
