import React, { useContext, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}


export const signIn = async (email, password, history) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    history.push("/admin");
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error("Error al iniciar sesión:", errorCode, errorMessage);

  }
}
export const signOut = async () => {
  await firebase.auth().signOut();
}


export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {

    currentUser,
    signIn,
    signOut

  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}


