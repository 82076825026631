import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/public/Home";
import Login from "./pages/public/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import { AdminRoutes } from "./routes/AdminRoutes.js";


function App()
{
  return (
    <>
    <AuthProvider>
      <Switch>
        <Route exact path="/" component={Home} />    
        <ProtectedRoute  path="/admin" component={AdminRoutes} />
        <Route path="/login" component={Login} />
      </Switch>
      </AuthProvider>
    </>
  );
}

export default App;
