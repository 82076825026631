import React from "react";
import { useAuth} from "../context/AuthContext"
import { GoSignOut } from "react-icons/go";

function LogOutButton()
{
  const { signOut } = useAuth();
  const handleExit = async() =>
  {
    try {
      await signOut();
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };
  return (
    <div>
      {/* <button className="btn btn-light" > </button> */}
      <GoSignOut size={30} onClick={handleExit}/>
    </div>
  );
}

export default LogOutButton;
