import React from "react";
// import { Link } from "react-router-dom";
import { GiBlackball } from "react-icons/gi";
import { IconContext } from "react-icons/lib";
import { HashLink as Link } from "react-router-hash-link";

function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top  ">
        <div className="container-fluid ">
          <Link className="navbar-brand" to="/">
            <IconContext.Provider
              value={{
                color: "#eb1974",
                size: "1.5em",
                className: "global-class-name",
              }}
            >
              <GiBlackball />
            </IconContext.Provider>
            <span> Esfero Sga</span>
          </Link>
          <button
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler bg-white shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
          >
            <span className="navbar-toggler-icon "></span>
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav  mb-2 mb-lg-0 ms-auto ">
              <Link className="nav-link" to="/#home">
                Home
              </Link>
              <Link smooth className="nav-link" to="/#about">
                Nosotros
              </Link>

              <li className="nav-item "></li>
              <li className="nav-item"></li>
              <li className="nav-item">
                <Link  smooth className="nav-link " aria-current="page" to="/#gallery">
                  Galeria
                </Link >
              </li>
              <li className="nav-item">
                <Link smooth className="nav-link " aria-current="page" to="/#location">
                  Ubicación
                </Link>
              </li>
            </ul>
          </div>

        </div>
      </nav>

    </>
  );
}

export default Header;
