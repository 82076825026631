import React from "react";
import { BsTrash } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import { useEffect } from "react";
import { storage } from "../../../firebase/index";
import fetchImages from "../../../services/fetchImages";
import { ToastContainer, toast } from "react-toastify";
function ImageTable({ files, setFiles }) {

  useEffect(() => {
    const loadImages = async () => {
      const urls = await fetchImages();
      setFiles(urls);
    };
    loadImages();
  }, []);
  const deletePic = (pic) => {
    // Create a reference to the file to delete
    console.log(pic)
    const desertRef = storage.refFromURL(pic);
    // Delete the file
    desertRef
      .delete()
      .then(function () {
        setFiles(prevFiles => prevFiles.filter(file => file !== pic))
        toast.success("Eliminada con éxito", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        toast.error("Ups, ocurrio un error", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const images = files.map((pic) =>
    pic ? (
      <tr key={pic}>
        <td>
          {pic.name}
          <img
            style={{ objectFit: "cover" }}
            height="150px"
            width="150px"
            src={pic}
            alt={pic.name}
          />
        </td>
        <td className="align-middle" onClick={() => deletePic(pic)}>
          <IconContext.Provider value={{ color: "#d90909", size: "30px" }}>
            <BsTrash />
          </IconContext.Provider>
        </td>
      </tr>
    ) : null
  );

  return (
    <>
      <ToastContainer />
      <table className="tableWrapper">
        <thead>
          <tr>
            <th scope="col">Imagen</th>
            <th scope="col">Eliminar</th>
          </tr>
          {images}
        </thead>
        <tbody className="text-left"></tbody>
      </table>
    </>
  );
}

export default ImageTable;
