import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storage } from "../../firebase/index";
import ImageTable from "./components/ImageTable";
import fetchImages from "../../services/fetchImages";
function EditCarousel() {
  const [image, setImages] = useState(null);
  const [files, setFiles] = useState([]);
  const handleImage = (event) => {
    if (event.target.files[0]) {
      setImages(event.target.files[0]);
    }
  };
  const handleUpload = () => {
    if (image) {
      const uploadTask = storage.ref(`carousel/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Cuando la subida es exitosa, actualizamos la lista de imágenes
          fetchImages().then((urls) => {
            setFiles(urls); // Aquí se actualiza el estado de files
          });
        }
      );
  
      toast.success("Imagen agregada con éxito", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Por favor, selecciona una imagen", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>

      <div className="col h-100 overflow-auto">

        <ToastContainer />

        <h5 className="">Añadir foto a la galeria</h5>

        <input type="file" title=" " className="form-control d-inline " onChange={handleImage} />


        <br />
        <button className="btn btn-dark mt-3 mr-10" onClick={handleUpload}>
          Subir
        </button>



        <ImageTable files={files} setFiles={setFiles} />
      </div>

    </>
  );
}

export default EditCarousel;
