import { useHistory } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";
export const BackButton = () =>
{
  let history = useHistory();
  return (
    <>
      {/* <button className="btn btn-light color-white"></button> */}
      <RiArrowGoBackFill size={30} onClick={() => history.goBack()}/>
    </>
  );
};