import React from "react";
import Footer from "./components/Footer";
import styled, { keyframes } from "styled-components";
import { tada } from "react-animations";

function Location()
{
  const fadeInAnimation = keyframes`${tada}`;
  const WspLogo = styled.div`
    position: fixed;
    right: 1%;
    bottom: 10%;
    animation: 10s ${fadeInAnimation};
  `;

  return (
    <>
      <div className="container" id="location">
        <div className="row">
          <div className="col-md-12 mt-1">
            <h5 className="text-center headingFont">
              Clases Online o Presenciales 2021
            </h5>
            <div
              className=" text-center position-relative map"
              style={{ height: "70vh" }}
            >
              <iframe
              title="sga map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.074433148329!2d-58.40360092454156!3d-34.7537177729009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcd290b80945ad%3A0x357f587e1847ec32!2sCIRCULO%20CAT%C3%93LICO%20DE%20OBREROS!5e0!3m2!1ses-419!2sar!4v1695082578995!5m2!1ses-419!2sar" width="90%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
              <WspLogo>
                <a href="https://api.whatsapp.com/send?phone=541134304441&text=%20!Hola!%20Me%20interesa%20saber%20m%C3%A1s%20sobre%20las%20clases">
                  {" "}
                  <img
                    src="https://cdn.worldvectorlogo.com/logos/whatsapp-3.svg"
                    alt=""
                    width=" 75px"
                    height="75px"
                  />{" "}
                </a>
              </WspLogo>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Location;
