import React, { useEffect, useState } from 'react'
import { database } from '../../firebase';
import { fetchTexts } from '../../services/fetchTexts';
import ReactQuill from 'react-quill';
import { ToastContainer, toast } from 'react-toastify';

export function EditInfo(props) {

    const [contenido, setContenido] = useState('');
    const [title, setTitle] = useState('');

    const handleChange = async () => {
        try {
            await database.collection('textos').doc('about').update({
                title: title,
                body: contenido
            });

            toast.success("Modificado con éxito", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } catch (error) {
            console.error('Error al actualizar el documento:', error);
            toast.error("Ocurrío un incoveniente, intentalo nuevamente", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // Aquí puedes agregar cualquier manejo de errores adicional que necesites
        }
    }


    useEffect(() => {
        fetchTexts()
            .then(textos => {
                console.log('Textos:', textos);
                const aboutObject = textos.find(item => item.id === 'about');

                // Ahora, puedes acceder al objeto 'aboutObject'.
                if (aboutObject) {
                    setContenido(aboutObject.body)
                    setTitle(aboutObject.title)
                } else {
                    console.log('No se encontró un objeto con id "about"');
                }
            })
            .catch(error => {
                console.error('Error al obtener los textos:', error);
            });
    }, []); // El [] asegura que el efecto se ejecute solo una vez

    return (
        <>
            <div className="col d-flex align-content-center h-100 w-100 flex-column mx-auto align-items-center mt-5 ">
            <ToastContainer />
                <h3 className='text-center mb-5'>Editar el contenido de Información general</h3>
                <div className="d-flex align-items-center flex-column AdminformWrapper">
                    <input
                        className='rounded border p-2 mb-5 w-100'
                        type="text"
                        placeholder="Título"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <ReactQuill
                        className='reacQuillStyles'
                        value={contenido}
                        onChange={(value) => setContenido(value)}
                    />

                </div>
                <div className="text-center">
                    <button onClick={() => handleChange()} type="button" className="mt-5 btn btn-success">Guardar Cambios</button>
                </div>

            </div>

        </>
    );
}
