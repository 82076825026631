import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import loginImg from "../../assets/images/login.jpg";
import { useAuth } from "../../context/AuthContext"

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useAuth()
  const handlePasswordReset = async () => {
    // si no ingreso mail
    if (!email) {
      toast.error('Introduzca una dirección de correo electrónico válida', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {


      firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
          toast.success('Se ha enviado un correo electrónico para restablecer tu contraseña.', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-email') {
            toast.error('Este correo electrónico no está registrado.', {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error(error);
            toast.error('Hubo un error al enviar el correo electrónico.', {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  };
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const email = event.target.user.value;
    const password = event.target.password.value;
    signIn(email, password, history)
  };

  return (
    <>
      <div className=" container min-vh-100 m-0 p-0 mx-auto my-auto ">
        <h3 className=" mt-5 text-center">Bienvenido</h3>

        <div className="row h-100 w-100 d-flex ">
          <div className="col-md-6 ">
            {" "}
            <img alt="login" className="w-100" src={loginImg} />
          </div>
          <div className="col-md-6  my-auto">
            {" "}
            <div Name="my-auto input-group d-flex justify-content-center my-auto align-items-center mx-auto">
              <h2 className="text-center mb-5">Login</h2>

              <form className="LoginForm" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="user"
                  className="form-control "
                  value={email} // Asigna el valor del estado a este campo
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Correo electrónico"
                  required
                />

                <input
                  name="password"
                  type="password"
                  required
                  className="form-control mt-3"
                  value={password} // Asigna el valor del estado a este campo
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Contraseña"
                ></input>
                <div className="d-flex mx-auto justify-content-center">
                  <button className="w-50 btn btn-dark mx-auto mt-5 " type="submit">
                    Ingresar
                  </button>
                </div>
              </form>
              <button
                type="button"
                className="btn text-black mx-auto d-flex mt-5"
                onClick={() => handlePasswordReset()}
              >
                ¿Olvidaste tu contraseña?
              </button>
            </div>

          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Login;
