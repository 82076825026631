import React, { useEffect, useState } from "react";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import fetchProfilePic from "../../services/fetchProfilePic";
import { fetchTexts } from "../../services/fetchTexts";

function About() {
  const [profile, setProfile] = useState("");
  const [contenido, setContenido] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    fetchTexts()
        .then(textos => {
            console.log('Textos:', textos);
            const aboutObject = textos.find(item => item.id === 'about');

            // Ahora, puedes acceder al objeto 'aboutObject'.
            if (aboutObject) {
                setContenido(aboutObject.body)
                setTitle(aboutObject.title)
            } else {
                console.log('No se encontró un objeto con id "about"');
            }
        })
        .catch(error => {
            console.error('Error al obtener los textos:', error);
        });
}, []); // El [] asegura que el efecto se ejecute solo una vez
  const handleimage = async () => {
    try {
      const pic = await fetchProfilePic();
      setProfile(pic);
    } catch (error) {
      console.error("Error al obtener la imagen del perfil:", error);
    }
  };

  useEffect(() => {
    handleimage();
  }, []);

  const fadeInAnimation = keyframes`${fadeIn}`;
  const MainAbout = styled.div`
    animation: 4s ${fadeInAnimation};
  `;
  return (
    <>
      <div className="container mt-5" id="about">
        <div className="row">
          <div className="col md-12 ">
            <div className="text-center ">
              <MainAbout>
                <img
                  style={{ objectFit: "cover", marginTop: "5%" }}
                  className=" rounded-circle"
                  height="150px"
                  width="150px"
                  src={profile}
                  alt=""
                />
              </MainAbout>
              <h4
                className="text-center mt-2 fw-light"
                style={{ fontFamily: "Chakra Petch" }}
              >
                {title}
              </h4>
            </div>
            <div dangerouslySetInnerHTML={{ __html: contenido }} />

          </div>
        </div>
      </div>
    </>
  );
}

export default About;
