import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import ilustration from "../../assets/images/stretching.jpg";
import { fadeInUp, fadeInLeft } from "react-animations";
import { fetchTexts } from "../../services/fetchTexts";
import 'react-quill/dist/quill.snow.css'; // Importar el estilo CSS

function Info() {
  const [contenido, setContenido] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    fetchTexts()
      .then(textos => {
        console.log('Textos:', textos);
        const aboutObject = textos.find(item => item.id === 'info');

        // Ahora, puedes acceder al objeto 'aboutObject'.
        if (aboutObject) {
          setContenido(aboutObject.body)
          setTitle(aboutObject.title)
        } else {
          console.log('No se encontró un objeto con id "about"');
        }
      })
      .catch(error => {
        console.error('Error al obtener los textos:', error);
      });
  }, []); // El [] asegura que el efecto se ejecute solo una vez
  const bounceAnimation = keyframes`${fadeInUp}}`;
  const fadeLeft = keyframes`${fadeInLeft}}`;

  const BouncyDiv = styled.div`
    animation: 4s ${bounceAnimation};
    right: 10%;
    width: 30vw;
    top: 105vh;
    position: absolute;
  `;
  const BouncyDivText = styled.h1`
    animation: 4s ${bounceAnimation};
    font-size: 2rem;
  `;
  const BouncyUl = styled.ul`
    font-size: 0.8rem;
    list-style-type: none;
    animation: 3s ${fadeLeft};
    padding: 5%;
    margin-top: 10vh;
    font-size: 1rem;
    margin-bottom: 7vh;
  `;

  return (
    <>
      <div className="container-fluid" id="info">
        <div className="row ">
          <div className="col-md-7 d-flex mt-1 ">
            <BouncyUl>
              <div className="mb-3">
                <BouncyDivText>{title}</BouncyDivText>
              </div>

             <div className="infoWrapper"  dangerouslySetInnerHTML={{ __html: contenido }}></div>
            </BouncyUl>
          </div>
          <div className="col-md-5 ">
            <BouncyDiv>
              <img src={ilustration} alt="" width="100%" />
            </BouncyDiv>
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;
