import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { storage } from "../../firebase";
import fetchProfilePic from "../../services/fetchProfilePic";

function EditProfilePic() {

  useEffect(() => {
    fetchProfilePic().then(url => {
      console.log(url); // Aquí puedes usar la URL de descarga
      setCurrentImg(url);
    }).catch(error => {
      console.error(error);
    });
  }, []);
  const [currentImg, setCurrentImg] = useState("");

  const [image, setImage] = useState(null);
  const handleImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("camio");
      const reader = new FileReader();

      reader.onload = () => {
        setCurrentImg(reader.result);
      };

      reader.readAsDataURL(file);
      setImage(file);
    }

  };






  const handleUpload = () => {
    if (image) {
      const uploadTask = storage.ref(`profilePic/pic-1`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log(error);
        }
      );
      toast.success("Imagen agregada con éxito", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } else {
      toast.error("Error, no se ha seleccionado ninguna imagen", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>

      <div className="col mt-5 text-center d-flex justify-content-center">

        <div className="mx-auto ">
          <ToastContainer />
          <h6 className="">Cambiar foto de perfil</h6>

          <div className=""> <img className="d-flex justify-content-center  mx-auto my-5" src={currentImg} alt="profilePicture" width={150} height={150} />
            <div className="mb-3">
              <input type="file" title=" " onChange={handleImage} className="form-control" />
            </div>
            <br />
            <button className="btn btn-dark mt-3 mr-10" onClick={handleUpload}>
              Subir
            </button></div>


        </div>

      </div>
    </>
  );
}

export default EditProfilePic;
