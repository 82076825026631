// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import 'firebase/storage';
import "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAs1vB7KEtVMrZcQq0x9X-mFJJZbgULevE",
  authDomain: "esfero-stretch.firebaseapp.com",
  projectId: "esfero-stretch",
  storageBucket: "esfero-stretch.appspot.com",
  messagingSenderId: "38961580376",
  appId: "1:38961580376:web:a8e6a49b523fd617b83d2d",
};
// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage();
export const auth=firebase.auth;
export const database=firebase.firestore();
